/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      { title: 'Dashboard', route: { name: 'dashboard-home' }, icon: 'HomeIcon', isBookmarked: false },
      { title: 'Business Partners', route: { name: 'japps-bp-list' }, icon: 'UsersIcon', isBookmarked: false },
      { title: 'Orders', route: { name: 'japps-order-list' }, icon: 'ShoppingBagIcon', isBookmarked: false },
      { title: 'Users', route: { name: 'japps-users-list' }, icon: 'UserCheckIcon', isBookmarked: false },
      
    ],
  },
  files: {
    key: 'file_name',
    data: [
    ]
  },
  contacts: {
    key: 'name',
    data: [
      
    ],
  },
}
/* eslint-enable */
